import GRID_TYPES from 'CommonUtils/gridTypes';

export const getProductPreviewImages = (product, gridType = GRID_TYPES.LITTLE_PRODUCTS, assetsBase = '') => {
  const color = product._my_colors?.find(color => color.preselected);
  if (color) {
    if (color.all_preview_images && color.all_preview_images.length) {
      const previewImages = color.all_preview_images
        .map((item) => {
          return {
            ratio: item.ratio,
            default:
              (gridType === GRID_TYPES.LITTLE_PRODUCTS ? item.sources.big : item.sources.zoom) ||
              item.default_source,
            big: item.sources.big || item.default_source || item.sources.zoom,
            zoom: item.sources.zoom || item.default_source || item.sources.big,
          };
        })
        .filter((item) => item.big);
      if (previewImages && previewImages.length && previewImages.length > 0) {
        return previewImages;
      }
    } else if (color.all_images && color.all_images.length) {
      const images = color.all_images
        .map((item) => {
          return {
            ratio: item.ratio,
            default:
              (gridType === GRID_TYPES.LITTLE_PRODUCTS ? item.sources.big : item.sources.zoom) ||
              item.default_source,
            big: item.sources.big || item.default_source || item.sources.zoom,
            zoom: item.sources.zoom || item.default_source || item.sources.big,
          };
        })
        .filter((item) => item.big);
      if (images && images.length && images.length > 0) {
        return images;
      }
    }
  } else if (product.priority_image && product.priority_image.default_source) {
    const priorityImage = [];
    priorityImage.push({
      ratio: product.priority_image.ratio,
      default:
        (gridType === GRID_TYPES.LITTLE_PRODUCTS
          ? product.priority_image.big
          : product.priority_image.zoom) || product.priority_image.default_source,
      big:
        product.priority_image.big ||
        product.priority_image.default_source ||
        product.priority_image.zoom,
      zoom:
        product.priority_image.zoom ||
        product.priority_image.default_source ||
        product.priority_image.big,
    });
    return priorityImage;
  }

  if (product.image && product.image.sources) {
    const defaultImage = [];
    defaultImage.push({
      ratio: product.image.ratio,
      default: product.image.default_source,
      big: product.image.sources.big,
      zoom: product.image.sources.zoom,
    });
    return defaultImage;
  }

  if (product.default_image && product.default_image.default_source) {
    const defaultImage = [];
    defaultImage.push({
      ratio: 1,
      default: `${assetsBase}/stylesheets/images/vuestore/noPictureBig.svg`,
      big: `${assetsBase}/stylesheets/images/vuestore/noPictureBig.svg`,
      zoom: `${assetsBase}/stylesheets/images/vuestore/noPictureZoom.svg`,
    });
    return defaultImage;
  }
  // Last resource
  const noImage = [];
  noImage.push({
    ratio: 1,
    default: 'https://www.elcorteingles.es/sgfm/SGFM/contents/images/common/no-image.png',
    big: 'https://www.elcorteingles.es/sgfm/SGFM/contents/images/common/no-image.png',
    zoom: 'https://www.elcorteingles.es/sgfm/SGFM/contents/images/common/no-image.png',
  });
  return noImage;
}

/**
 * Returns an image source url
 * @param {object} image - Object containing the url's for differente sources
 * @param {string} preferredSource - Preferred source to return from the image object.
 * @param {boolean} [preferBigger=true] - Subsequent preferred sources when preferredSource doesn't exist
 * @returns {string}
 */
export const getImageBySource = (image, preferredSource = 'big', preferBigger = true) => {
  if (!image) return;
  const sources = ['zoom', 'big', 'medium', 'small'];
  let startIndex = sources.indexOf(preferredSource);
  if (startIndex < 0) startIndex = 0;
  const key = sources[startIndex];
  const sortedSources = preferBigger ?
    [key].concat(sources.slice(0, startIndex)).concat(sources.slice(startIndex+1)) :
    [key].concat(sources.slice(startIndex+1)).concat(sources.slice(0, startIndex).reverse());

  for (const source of sortedSources) {
    if (image[source]) {
      return image[source];
    }
  }
  return image.default;
}